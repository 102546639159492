import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Card } from "reactstrap"
import BackgroundImage from "gatsby-background-image"
import Newsletter from "../components/newsletter"

const TeamMember = ({ img, title, subtitle }) => (
  <Col className="mb-4">
    <Card className="border-0 p-sm-4 text-center">
      <Img fluid={img} className="rounded-circle mb-3" />
      <h3 className="h5">{title}</h3>
      <h4 className="h6 text-muted lead">{subtitle}</h4>
    </Card>
  </Col>
)

export default ({ data }) => {
  const backgroundFluidImageStack = [
    data.banner.childImageSharp.fluid,
    `linear-gradient(to right, rgba(2, 47, 96, 0.9), rgba(2, 47, 96, 0.6))`
  ].reverse();

  return (<Layout>
    <SEO
      title="Team"
      description="We are a complementary group with background in logistics, management and informatics."
    />

    <BackgroundImage
      Tag="section"
      className="d-flex justify-content-center align-items-center vh-60"
      fluid={backgroundFluidImageStack}
      backgroundColor={`#022f60`}
    >
      <Container>
        <Row>
          <Col lg="4">
            <h1 className="display-3 text-white">Our Team.</h1>
            <p className="lead text-white">
              We are a complementary group with background in logistics,
              management and informatics.
            </p>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>

    <section className="py-5">
      <Container>
        <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4">
          <TeamMember
            img={data.markusImg.childImageSharp.fluid}
            title="FH-Prof. Dr. Markus Gerschberger"
            subtitle="Head of Josef Ressel Center for Real-time Value Network Visibility" />

          <TeamMember
            img={data.valentinImg.childImageSharp.fluid}
            title="Dr. Valentin Schmidt"
            subtitle="Sustainability Expert" />

          <TeamMember
            img={data.christopherImg.childImageSharp.fluid}
            title="Christopher Schwarz"
            subtitle="Researcher" />

          <TeamMember
            img={data.christophImg.childImageSharp.fluid}
            title="Univ.-Prof. Dr. Christoph Bode"
            subtitle="University of Mannheim" />

          <TeamMember
            img={data.georgImg.childImageSharp.fluid}
            title="Univ.-Prof. Dr. Georg Gottlob"
            subtitle="University of Oxford/TU Wien" />

          <TeamMember
            img={data.stefanImg.childImageSharp.fluid}
            title="Univ.-Prof. Dr. Stefan Pickl"
            subtitle="University of the Federal Armed Forces in Munich" />

          <TeamMember
            img={data.reinhardImg.childImageSharp.fluid}
            title="Dr. Reinhard Tockner"
            subtitle="Tableau Expert" />

          <TeamMember
            img={data.romanaImg.childImageSharp.fluid}
            title="Romana Traxler"
            subtitle="Controlling" />
        </Row>
      </Container>
    </section>

    <Newsletter />
  </Layout>
  )
}

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "team.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    markusImg: file(relativePath: { eq: "team/Markus_Gerschberger.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marcelImg: file(relativePath: { eq: "team/Marcel_Hadek.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    martinImg: file(relativePath: { eq: "team/Martin_Alalibo.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    christopherImg: file(relativePath: { eq: "team/Christopher_Schwarz.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    valentinImg: file(relativePath: { eq: "team/Valentin_Schmidt.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    christianImg: file(relativePath: { eq: "team/Christian_Wallmann.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    christophImg: file(relativePath: { eq: "team/Christoph_Bode.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    georgImg: file(relativePath: { eq: "team/Georg_Gottlob.png" }) {
      childImageSharp {
        fluid(maxHeight: 400, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stefanImg: file(relativePath: { eq: "team/Stefan_Pickl.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    romanaImg: file(relativePath: { eq: "team/Romana_Traxler.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    reinhardImg: file(relativePath: { eq: "team/Reinhard_Tockner.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 400, maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`